<template>
  <SearchTrainUI @chooseTicket="chooseTicket" :hideCheck="true"/>
</template>

<script>
import SearchTrainUI from "./components/SearchTrainUI";
export default {
  name: "BuyTrainView",
  components: { SearchTrainUI },
  methods: {
    // 选票
    chooseTicket() {},
  },
};
</script>
